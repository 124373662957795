import http from "../../utils/http"

/**
 * 获取公告列表
 */
export function noticesList(params) {
    return http({
        url: "/api/notice/page",
        data: params
    })
}

/**
 * 获取公告详情
 */
export function noticeDetail(params) {
    return http({
        url: "/api/notice/info",
        data: params
    })
}

/**
 * 获取公告详情
 */
export function noticeGuide(params) {
    return http({
        url: "/api/pc/guide",
        data: params
    })
}


/**
 * 获取新的
 */
export function getNoticeList(params) {
    return http({
        url: "/api/notice/page/parent_id",
        data: params
    })
}
/**
 * 导航
 * @param {Object} params 参数
 */
export function daohang() {
    return http({
        url: '/api/pc/daohang',
    })
}