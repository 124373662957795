<template>
	<div class="bread-nav">
		<el-breadcrumb class="breadcrumb" separator="/">
			<el-breadcrumb-item 
				:to="item.titlePath || item.redirect || item.path"
				v-for='(item,index) in matchedArr'
				:key='index'
				>
				{{ item.meta.title || item.name }}
			</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
    export default {
		name: 'bread-nav',
        props: {
			
		},
        data() {
            return {
				
			}
        },
		computed:{
			matchedArr(){
				let temp = [],temps = [];
				this.$route.matched.filter((item,index,self) => {
          //自营产品
          if (item.path == "/category") {
            if (this.$route.query.self_support == 1) {
              item.meta.title = "自营产品"
            } else {
              item.meta.title = "全部商品"
            }
          }
					// const title = item.meta.title || item.name;
					temp.push(item)
				});
				temp.filter((item,index,self) => {
					if(!temps.includes(item)){
						temps.push(item);
					}
				})
				return temps;
			}
		}
    }
</script>

<style lang="scss">
.bread-nav {
	.el-breadcrumb__item {
		&:last-child {
			.el-breadcrumb__inner {
			   color: $base-color;
			}
		}
	}
	.el-breadcrumb__inner {
		
		cursor: pointer!important;
	}
}
</style>
