<template>
    <div class="footer">
        <div class="footer-top">
            <div class="top-item" v-for="(item, index) in footerTop" :key="index">
                <img :src="item.icon" />
                {{ item.text }}
            </div>
        </div>
        <div class="footer-center">
            <div class="center-wrap">
                <!-- 公告 -->
                <div class="center-item" v-if="helpList[0]">
                    <div class="center-title">{{ helpList[0].class_name }}<router-link to="/cms/notice" target="_blank">查看更多</router-link></div>
                    <div class="list" v-for="item in noticeList.slice(0, 5)" :key="item.id" @click="detil(item.id)">
                        <div class="item">{{ item.title }}</div>
                    </div>
                </div>
                <!-- 合作与咨询 -->
                <div class="center-item" v-if="helpList[1]">
                    <div class="center-title">
                        {{ helpList[2].class_name }}
                    </div>
                    <div class="content-middle">
                        <!-- <div class="address">{{ zongbudizhi.title }}:{{ zongbudizhi.content }}</div> -->
                        <div class="address">{{ zongbudizhi.title }}</div>
                        <div class="second_maring_right">
                            <div class="phone-buttom" v-for="(item, index) in phone" :key="index">
                                <div class="title">{{ item.title }}</div>
                                <div class="phone-text">{{ item.content }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 家之物语导航 -->
                <div class="center-item" v-if="helpList[2]">
                    <div class="center-title">
                        {{ helpList[3].class_name }}
                    </div>
                    <div class="center-con">
                        <a class="nav-item" :href="item.a" target="_blank" v-for="(item, index) in footerNav" :key="index">
                            <!-- <img :src="item.icon" /> -->
                            <img :src="'https://admin.sungsavip.com/' + item.image" alt="" />
                            <p>{{ item.title }}</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-bot"><copy-right /></div>
    </div>
</template>

<script>
import { noticesList } from "@/api/cms/notice"
import { copyRight, shopServiceLists, friendlyLink, weQrcode } from "@/api/website"
import CopyRight from "./CopyRight"
import { mapGetters } from "vuex"
import { helpList, daoHang } from "@/api/cms/help"
import axios from "axios"

export default {
    props: {},
    data() {
        return {
            shopServiceList: [],
            linkList: [],
            helpList: [],
            // daoHang:[],
            ishide: false,
            activeName: "first",
            qrcode: "",
            queryinfo: {
                page: 1,
                page_size: 10,
                receiving_type: "web"
                // parent_id:''
            },
            brandList: [],
            noticeList: [],
            holidayList: [],
            total: 0,
            loading: true,
            hezuozixun: [],
            gonggao: "",
            hezuo: "",
            jiazhiwuyudaohang: "",
            footerTop: [
                {
                    icon: require("img/footer/pinzhi.png"),
                    text: "品质保证，服务周到"
                },
                {
                    icon: require("img/footer/shopping.png"),
                    text: "轻松购物，物有所值"
                },
                {
                    icon: require("img/footer/express.png"),
                    text: "极速物流，超快配送"
                },
                {
                    icon: require("img/footer/shouhou.png"),
                    text: "贴心售后，专业解答"
                }
            ],
            footerNav: "",
            zongbudizhi: "",
            phone: ""
        }
    },
    computed: {
        ...mapGetters(["siteInfo"])
    },
    created() {
        this.getShopServiceLists()
        this.link()
        this.getList()
        this.getHelpList()
        this.getqrcodeimg()
        this.getDaoHang()
    },
    mounted() {},
    watch: {},
    methods: {
        detil(id) {
            this.$router.push("/cms/notice-" + id)
        },
        // 底部导航
        getDaoHang() {
            // let { mobile, key, dynacode: code, password } = this.registerForm
            axios
                .post("https://admin.sungsavip.com/api/pc/daohang", {})
                .then((res) => {
                    // console.log(res.data[2])
                    this.footerNav = res.data[3].list
                    this.zongbudizhi = res.data[1].list[0]
                    this.phone = res.data[2].list
                    console.log(this.phone)
                })
                .catch((rej) => {
                    console.log(rej)
                })
        },
        getList() {
            noticesList(this.queryinfo)
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        // this.noticeList = res.data.list
                        this.brandList = res.data.list[0]
                        // console.log(res.data.list)
                        this.noticeList = res.data.list[1]
                        this.holidayList = res.data.list[2]
                        this.total = res.data.count
                        // console.log(555, this.noticeList)
                        // for(let i in res.data.list[1])
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        getqrcodeimg() {
            weQrcode({})
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.qrcode = res.data
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        getShopServiceLists() {
            shopServiceLists({})
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.shopServiceList = res.data
                    }
                })
                .catch((err) => {
                    this.shopServiceList = []
                })
        },
        link() {
            friendlyLink({})
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.linkList = res.data
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        linkUrl(url, target) {
            if (!url) return
            if (url.indexOf("http") == -1) {
                if (target)
                    this.$router.pushToTab({
                        path: url
                    })
                else
                    this.$router.push({
                        path: url
                    })
            } else {
                if (target) window.open(url)
                else window.location.href = url
            }
        },

        /**
         * 获取帮助列表
         */
        getHelpList() {
            helpList()
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        var arr = []
                        arr = res.data.slice(0, 4)
                        // console.log(arr)
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].child_list = arr[i].child_list.slice(0, 4)
                        }

                        this.helpList = arr
                        // console.log(helpList)
                    }
                })
                .catch((err) => {})
        },
        /**
         * 图片加载失败
         */
        imageError() {
            this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
        },
        /**
         * 跳转到帮助列表
         */
        clickToHelp(id) {
            this.$router.push("/cms/help/listother-" + id)
        },
        /**
         * 跳转到帮助详情
         */
        clickToHelpDetail(id) {
            this.$router.push("/cms/help-" + id)
        },
        /**
         * 跳转到帮助详情
         */
        clickJump(address) {
            location.href = address
        }
    },
    components: {
        CopyRight
    }
}
</script>
<style lang="scss">
.el-footer {
    padding-top: 0 !important;
}
</style>
<style scoped lang="scss">
.footer {
    background-color: $base-color-gray;

    .footer-top {
        width: $width;
        height: 80px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-item {
            color: $ns-text-color-black;
            font-size: 20px;
            font-weight: bold;

            img {
                width: 28px;
                height: 28px;
                margin-right: 5px;
            }
        }
    }

    .footer-center {
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        padding: 20px 0;

        .center-wrap {
            width: $width;
            margin: 0 auto;

            &:after {
                clear: both;
                content: "";
                display: block;
                width: 0;
            }

            .center-item {
                float: left;

                &:nth-child(1) {
                    width: 440px;
                }

                &:nth-child(2) {
                    width: 500px;
                    margin-left: 60px;
                }

                &:nth-child(3) {
                    width: 440px;
                    margin-left: 60px;
                }

                .center-title {
                    color: $ns-text-color-black;
                    font-size: 18px;
                    position: relative;
                    padding: 0 5px 5px;
                    font-weight: bold;
                    border-bottom: 1px solid #d9d9d9;

                    a {
                        font-size: $ns-font-size-base;
                        font-weight: 400;
                        position: absolute;
                        right: 5px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }

                .center-con {
                    padding-top: 10px;
                }
            }

            .news-item {
                display: block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin: 0 5px;
                font-size: $ns-font-size-base;
                line-height: 30px;
                cursor: pointer;

                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    display: inline-block;
                    background-color: $ns-text-color-gray;
                    vertical-align: 2px;
                    margin-right: 5px;
                }

                &:hover {
                    color: $base-color;
                }

                &:hover:before {
                    background-color: $base-color;
                }
            }

            .consult {
                &:after {
                    clear: both;
                    content: "";
                    width: 0;
                    display: block;
                }

                .consult-item {
                    float: left;
                    width: 180px;
                    margin-right: 40px;

                    &:nth-child(2n) {
                        margin-right: 0;
                    }
                }
            }

            .nav-item {
                width: 84px;
                margin-right: 69px;
                margin-bottom: 30px;
                display: inline-block;
                vertical-align: top;

                img {
                    width: 40px;
                    height: 40px;
                    margin: 0 auto;
                    display: block;
                }

                p {
                    text-align: center;
                    color: $ns-text-color-gray;
                    font-size: $ns-font-size-lg;
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}
.content-middle {
    .address {
        margin-bottom: 20px;
    }
    .phone-top {
        .left {
            float: left;
            margin-right: 100px;
            height: 70px;
        }
    }
}
.title {
    font-weight: bold;
}
.phone-buttom {
    float: left;
    margin-right: 100px;
    &:nth-child(2n) {
        // color: #f00;
        margin-right: 0px;
    }
}
.second_maring_right {
}
</style>
