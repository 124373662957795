<template>
    <el-container class="login-master" 
		>
		<!-- :style="`background-image: url(${require('img/static/login.jpg')})`"> -->
        <el-main>
            <transition name="slide"><router-view /></transition
        ></el-main>
    </el-container>
</template>
<script>
    import { mapGetters } from "vuex"

    export default {
        name: "Layout",
        components: {
        },
        created() {
            this.$store.dispatch("site/siteInfo")
        },
        mounted() {},
        computed: {
            ...mapGetters(["siteInfo"])
        },

        watch: {},
        methods: {}
    }
</script>
<style lang="scss">
    .login-master {
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: #ffffff;
		// background-size: cover;
	}
</style>
