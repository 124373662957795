import BasicLayout from "../../layout/basic"

const authRoutes = [{
	path: "/register",
	name: "register",
	meta: {
		module: "login",
		backgroundColor: "#fff",
		title: '注册'
	},
	component: () => import("@/views/auth/register")
}, {
	path: "/privacy",
	name: "privacy",
	meta: {
		module: "login",
		backgroundColor: "#fff",
		title: '服务协议'
	},
	component: () => import("@/views/auth/privacy")
},
{
	path: "/find_pass",
	name: "find_pass",
	meta: {
		module: "login",
		backgroundColor: "#fff",
		title: '忘记密码'
	},
	component: () => import("@/views/auth/find")
}
]

const promotionRoutes = [
	//******************组合套餐模块（2）******************
	{
		path: "/promotion/combo-*",
		name: "combo",
		meta: {
			module: "combo"
		},
		component: () => import("@/views/promotion/combo/detail")
	},
	{
		path: "/promotion/combo_payment",
		name: "combo_payment",
		meta: {
			module: "combo",
			auth: true
		},
		component: () => import("@/views/promotion/combo/payment")
	},

	//******************秒杀模块（3）******************
	{
		path: "/promotion/seckill-*",
		name: "seckill_detail",
		meta: {
			module: "seckill",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/seckill/detail")
	},
	{
		path: "/promotion/seckill",
		name: "seckill",
		meta: {
			title: '限时特价',
			module: "seckill",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/seckill/list")
	},
	{
		path: "/promotion/seckill_payment",
		name: "seckill_payment",
		meta: {
			module: "seckill",
			auth: true
		},
		component: () => import("@/views/promotion/seckill/payment")
	},

	//******************团购模块（3）******************
	{
		path: "/promotion/groupbuy-*",
		name: "groupbuy_detail",
		meta: {
			module: "groupbuy",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/groupbuy/detail")
	},
	{
		path: "/promotion/groupbuy",
		name: "groupbuy",
		meta: {
			module: "groupbuy",
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/promotion/groupbuy/list")
	},
	{
		path: "/promotion/groupbuy_payment",
		name: "groupbuy_payment",
		meta: {
			module: "groupbuy",
			auth: true
		},
		component: () => import("@/views/promotion/groupbuy/payment")
	},

	//******************专题活动模块（4）******************
	{
		path: "/promotion/topic-*",
		name: "topic_detail",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/detail")
	},
	{
		path: "/promotion/topic",
		name: "topic",
		meta: {
			module: "topic"
		},
		component: () => import("@/views/promotion/topic/list")
	},
	{
		path: "/promotion/topic-goods-*",
		name: "topic_goods_detail",
		meta: {
			module: "topic",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/promotion/topic/goods_detail")
	},
	{
		path: "/promotion/topic_payment",
		name: "topic_payment",
		meta: {
			module: "topic",
			auth: true
		},
		component: () => import("@/views/promotion/topic/payment")
	}
]

const cmsRoutes = [
	{
		path: "/cms/guide",
		name: "notice_guide",
		meta: {
			module: "notice",
			backgroundColor: "#fff",
			title: '新手指南'
		},
		component: () => import("@/views/cms/notice/guide")
	},
	{
		path: "/cms/notice",
		name: "notice",
		meta: {
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/list")
	},
	{
		path: "/cms/notice-*",
		name: "notice_detail",
		meta: {
			title: '通知',
			module: "notice",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/notice/detail")
	},
	{
		path: "/cms/introduce",
		name: "introduce",
		meta: {
			title: '公司介绍',
			module: "introduce",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/introduce/introduce")
	},
	{
		path: "/cms/helpcenter",
		name: "introduce",
		meta: {
			title: '公司介绍',
			module: "introduce",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/helpcenter/helpcenter")
	},
	{
		path: "/cms/help",
		name: "help",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/list"),
		children: [{
			path: "/cms/help/listother-*",
			name: "list_other",
			meta: {
				module: "help",
				backgroundColor: "#fff"
			},
			component: () => import("@/views/cms/help/listother")
		}]
	},
	{
		path: "/cms/help-*",
		name: "help_detail",
		meta: {
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/detail")
	},
	{
		path: "/download",
		name: "download",
		meta: {
			title: '资料下载',
			module: "help",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/cms/help/download")
	}
]

const goodsRoutes = [
	//******************商品模块（6）******************
	{
		path: "/goods/style/two",
		name: "kantuxiadan",
		meta: {
			module: "goods",
			title: '看图下单',
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/goods/kantuxiadan")
	},
	{
		path: "/goods/style/three",
		name: "piliangxiadan",
		meta: {
			module: "goods",
			title: '批量下单',
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/goods/piliangxiadan")
	},
	{
		path: "/goods/style/four",
		name: "yuding",
		meta: {
			module: "goods",
			title: '商品预订',
		},
		component: () => import("@/views/goods/yuding")
	},
	{
		path: "/cart",
		name: "cart",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/cart")
	},
	{
		path: "/category",
		name: "category",
		meta: {
			title: '自营产品',
			module: "goods"
		},
		component: () => import("@/views/goods/category")
	},
	{
		path: "/total",
		name: "total",
		meta: {
			title: '全部商品',
			module: "goods"
		},
		component: () => import("@/views/goods/total")
	},
	{
		path: "/coupon",
		name: "coupon",
		meta: {
			module: "goods"
		},
		component: () => import("@/views/goods/coupon")
	},
	{
		path: "/sku-*",
		name: "detail",
		meta: {
			title: '商品详情',
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/detail")
	},
	{
		path: "/list",
		name: "list",
		meta: {
			title: '商品列表',
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/list")
	},
	{
		path: "/brand",
		name: "brand",
		meta: {
			title: '品牌导航',
			module: "goods",
			backgroundColor: "#fff"
		},
		component: () => import("@/views/goods/brand")
	}
]

// 其他模块
export default {
	path: "/",
	component: BasicLayout,
	redirect: "/index",
	name: "Index",
	meta: {
		title: "首页"
	},
	children: [{
		path: "/index*",
		name: "index",
		meta: {
			mainCss: {
				width: "100%"
			},
		},
		component: () => import("@/views/index/index")
	},
	{
		path: "/introduce",
		name: "introduce",
		meta: {
			backgroundColor: "#f4f4f4"
		},
		component: () => import("@/views/introduce")
	},
	...goodsRoutes,
	...cmsRoutes,
	...promotionRoutes,
	...authRoutes
	]
}
