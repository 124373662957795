<template>
    <div class="header-in">
        <!-- 未登录 -->
        <el-row v-if="!logined">
            <el-col :span="8">
                <router-link to="/login" class="logo-wrap">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" style="width: 200px; height: 57px;" />
                    <img v-else src="@/assets/images/logo.png" />
                </router-link>
            </el-col>
            <el-col :span="15" class="cart-box">
                <div class="in-sousuo">
                    <div class="sousuo-box">
                        <div class="sousuo-inner-box">
                            <input type="text" :placeholder="defaultSearchWords" v-model="keyword" maxlength="50" />
                            <div class="sousuo-tips">关键词，产品名称，型号，条形码均可搜索</div>
                        </div>
                        <!-- <el-button type="primary" size="small" @click="search">搜索</el-button> -->
                        <router-link to="/login">
                            <el-button type="primary" size="small">搜索</el-button>
                        </router-link>
                        <!-- <el-button type="primary" size="small">搜索</el-button> -->
                    </div>
                    <div class="hot-search-words" v-if="hotSearchWords.length">
                        <span>热门搜索：</span>
                        <ul>
                            <li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
                <a class="cart" @click="jumpLoginPath('/member/cart')">
                    <i class="iconfont icongouwuche"></i>
                    <el-badge v-if="cartCount" :value="cartCount" type="primary" class="mycart-number"></el-badge>
                    <el-badge v-else type="primary"> </el-badge>
                    <span>我的购物车</span>
                </a>
            </el-col>
        </el-row>
        <!-- 已登录 -->
        <el-row v-if="logined">
            <el-col :span="8">
                <router-link to="/" class="logo-wrap">
                    <img v-if="siteInfo.logo" :src="$img(siteInfo.logo)" style="width: 200px; height: 57px" />
                    <img v-else src="@/assets/images/logo.png" />
                </router-link>
            </el-col>
            <el-col :span="15" class="cart-box">
                <div class="in-sousuo">
                    <div class="sousuo-box">
                        <div class="sousuo-inner-box">
                            <input type="text" :placeholder="defaultSearchWords" v-model="keyword" @keyup.enter="search" maxlength="50" />
                            <div class="sousuo-tips">关键词，产品名称，型号，条形码均可搜索</div>
                        </div>
                        <el-button type="primary" size="small" @click="search">搜索</el-button>
                    </div>
                    <div class="hot-search-words" v-if="hotSearchWords.length">
                        <span>热门搜索：</span>
                        <ul>
                            <li v-for="(item, index) in hotSearchWords" :key="index" @click="$router.push({ path: '/list', query: { keyword: item, search_type: 'goods' } })">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
                <a class="cart" @click="jumpLoginPath('/member/cart')">
                    <i class="iconfont icongouwuche"></i>
                    <el-badge v-if="cartCount" :value="cartCount" type="primary" class="mycart-number"></el-badge>
                    <el-badge v-else type="primary"> </el-badge>
                    <span>我的购物车</span>
                </a>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { apiHotSearchWords, apiDefaultSearchWords } from "@/api/pc"
import { cartList as apiCartList } from "@/api/goods/cart"
export default {
    props: {},
    data() {
        return {
            searchType: "goods",
            searchTypeText: "商品",
            keyword: "",
            hotSearchWords: [],
            defaultSearchWords: "",
            cartList: [],
            cartTotalPrice: 0
        }
    },
    components: {},
    computed: {
        ...mapGetters(["cartCount", "siteInfo", "defaultGoodsImage", "member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    },
    created() {
        this.keyword = this.$route.query.keyword || ""
        if (this.$route.name == "street") this.searchType = "shop"
        else this.searchType = "goods"
        this.$store.dispatch("site/siteInfo")
        this.getHotSearchWords()
        this.getDefaultSearchWords()
        this.getCartList()
    },
    watch: {
        searchType() {
            this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
        },
        $route(curr) {
            if (this.keyword !== curr.query.keyword) {
                this.keyword = curr.query.keyword
            }
            if (this.$route.name == "street") this.searchType = "shop"
            else this.searchType = "goods"
        },
        cartCount() {
            if (this.member) this.getCartList()
            console.log("购物车数量：" + this.cartCount)
        },
        member() {
            if (!this.member) {
                this.$store.commit("cart/SET_CART_COUNT", 0)
                this.cartList = []
                this.cartTotalPrice = 0
            }
        }
    },
    methods: {
        handleCommand(command) {
            this.searchType = command
        },
        search() {
            if (this.searchType == "goods")
                this.$router.replace({
                    path: "/total",
                    query: {
                        keyword: this.keyword ? this.keyword : this.defaultSearchWords
                    }
                })
            else
                this.$router.replace({
                    path: "/street",
                    query: {
                        keyword: this.keyword
                    }
                })
        },
        jumpLoginPath( url ) {
            if( this.logined )
            {
                this.$router.push(url);
            } else {
                return this.$message.error("您尚未登录，请先进行登录");
            }
        },
        getHotSearchWords() {
            apiHotSearchWords({}).then((res) => {
                if (res.code == 0 && res.data.words) {
                    this.hotSearchWords = res.data.words.split(",")
                }
            })
        },
        getDefaultSearchWords() {
            apiDefaultSearchWords({}).then((res) => {
                if (res.code == 0 && res.data.words) {
                    this.defaultSearchWords = res.data.words
                }
            })
        },
        // 获取购物车数据
        getCartList() {
            apiCartList({})
                .then((res) => {
                    if (res.code >= 0 && res.data.length) {
                        this.cartList = res.data
                        this.cartTotalPrice = 0
                        this.cartList.forEach((item) => {
                            // console.log(item.discount_price, '单价')
                            let _cartTotalPrice = parseFloat(item.discount_price) * parseInt(item.num)
                            this.cartTotalPrice += parseFloat(_cartTotalPrice)
                            // console.log(this.cartTotalPrice)
                        })
                        this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
                    }
                })
                .catch((res) => {})
        },
        imageError(index) {
            this.cartList[index].sku_image = this.defaultGoodsImage
        },
        // 删除单个购物车商品
        deleteCart(index) {
            this.$store
                .dispatch("cart/delete_cart", {
                    cart_id: this.cartList[index].cart_id.toString()
                })
                .then((res) => {
                    if (res.code >= 0) {
                        this.cartList.splice(index, 1)
                        this.cartList.forEach((item) => {
                            let _cartTotalPrice = parseFloat(item.discount_price) * parseFloat(item.num)
                            this.cartTotalPrice += parseFloat(_cartTotalPrice)
                        })
                        // console.log(this.cartTotalPrice)
                        this.cartTotalPrice = this.cartTotalPrice.toFixed(2)
                        this.$forceUpdate()
                    }
                })
                .catch((err) => {})
        }
    }
}
</script>

<style scoped lang="scss">
.header-in {
    width: $width;
    height: 80px;
    margin: 20px auto 0;

    .logo-wrap {
        width: 240px;
        height: 68px;
        line-height: 68px;
        display: block;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .cart-box {
        display: flex;
        align-items: flex-end;
        padding-left: 35px;
    }

    .in-sousuo {
        width: 600px;
        margin-top: 10px;

        .sousuo-box {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: flex-start;
            .el-dropdown {
                padding: 0 10px;
                cursor: pointer;

                &::after {
                    content: "";
                    border-left: 1px solid #cfcfcf;
                    margin-left: 5px;
                }
            }

            input {
                border-radius: 20px;
                border: 1px solid $base-color-info;
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                box-sizing: border-box;
                width: 490px;
                height: 40px;
                background: none;
                outline: none;
                box-sizing: border-box;
                padding-left: 15px;
            }

            button {
                width: 80px;
                height: 40px;
                border-radius: 0;
                float: right;
                border-top-right-radius: 20px;
                border-bottom-right-radius: 20px;
            }

            .sousuo-tips {
                color: $base-color-info;
                font-size: $ns-font-size-sm;
                margin-top: 5px;
            }
        }

        .hot-search-words {
            width: 100%;
            height: 20px;
            margin-top: 5px;
            font-size: 12px;

            span {
                float: left;
            }

            ul {
                overflow: hidden;
                margin: 0;
                height: 21px;
                padding: 0;
                color: $ns-text-color-black;

                li {
                    cursor: pointer;
                    list-style: none;
                    float: left;
                    margin-right: 10px;

                    &:hover {
                        color: $base-color;
                    }
                }
            }
        }
    }

    .cart {
        margin-top: 10px;
        width: 95px;
        height: 40px;
        padding: 0 19px;
        border: 1px solid $ns-text-color-gray;
        border-radius: 20px;
        color: $ns-text-color-gray;
        font-size: $ns-font-size-base;
        display: block;
        position: absolute;
        right: 245px;
        z-index: 101;

        span {
            cursor: pointer;
            line-height: 40px;
        }

        i {
            margin-right: 5px;
            color: $base-color;
        }
    }

    .cart-wrap {
        position: relative;

        .cart {
            margin-top: 10px;
            width: 95px;
            height: 36px;
            padding: 0 28px 0 19px;
            border: 1px solid #dfdfdf;
            color: $base-color;
            font-size: $ns-font-size-sm;
            display: block;
            position: absolute;
            right: 0;
            z-index: 101;

            span {
                cursor: pointer;
                line-height: 38px;
                margin-right: 10px;
            }
        }

        &:hover {
            .cart {
                border-bottom: 1px solid #ffffff;
            }

            .list {
                display: block;
            }
        }

        .list {
            position: absolute;
            top: 47px;
            right: 0;
            width: 340px;
            background: #fff;
            border: 1px solid #dfdfdf;
            display: none;
            z-index: 100;

            h4 {
                height: 25px;
                padding: 6px 8px;
                line-height: 25px;
            }

            .overflow-wrap {
                width: 340px;
                overflow: hidden;

                ul {
                    max-height: 335px;

                    &.overflow {
                        overflow: auto;
                        width: 354px;
                    }

                    li {
                        background-color: #fff;
                        display: block;
                        font-size: 12px;
                        padding: 8px 10px;
                        position: relative;
                        border-bottom: 1px solid #dfdfdf;
                        overflow: hidden;

                        .img-wrap {
                            width: 50px;
                            height: 50px;
                            margin-right: 5px;
                            overflow: hidden;
                            float: left;
                            text-align: center;
                            line-height: 50px;
                        }

                        .goods-name {
                            float: left;
                            width: 140px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-right: 10px;
                        }

                        .operation {
                            float: right;
                            text-align: right;
                            width: 90px;

                            p {
                                color: $base-color;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                            span {
                                cursor: pointer;

                                &:hover {
                                    color: $base-color;
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }

            .total {
                background-color: #fff;
                display: block;
                font-size: 12px;
                padding: 8px 10px;
                position: relative;
                border-bottom: 1px solid #dfdfdf;
                overflow: hidden;
                background-color: #f0f0f0;
                border-bottom: 0;
                left: 0;

                span {
                    margin-top: 5px;
                    display: inline-block;
                    width: 70%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    strong {
                        margin: 0 2px;
                        color: $base-color;
                        font-size: $ns-font-size-base;
                    }
                }

                button {
                    float: right;
                }
            }

            .empty {
                width: auto;
                height: 70px;
                line-height: 70px;
                text-align: center;
                color: #999;

                i {
                    font-size: 28px;
                }

                span {
                    display: inline-block;
                    font-size: 12px;
                    padding-right: 20px;
                    margin-left: 10px;
                }
            }
        }
    }
}
.mycart-number {
    position: absolute;
    right: 4px;
    top: 0px;
}
</style>
