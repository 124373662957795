import http from "../../utils/http"

/**
 * 获取商品分类树结构
 */
export function tree(params) {
    return http({
        url: "/api/goodscategory/tree",
        data: params
    })
}

/**
 * 获取商品分类信息
 * @param {Object} params 参数 category_id:1
 */
export function goodsCategoryInfo(params) {
    return http({
        url: "/api/goodscategory/info",
        data: params
    })
}

/**
 * 获取分类下商品信息
 * @param {Object} params 参数 category_id:1
 */
export function categoryGoods(params) {
    return http({
        url: "/api/goods/categoryGoods",
        data: params
    }, 0, 'GET')
}

/**
 * 自营商品筛选
 */
export function screen(params) {
    return http({
        url: "/api/goodscategory/screen",
        data: params
    })
}
/**
 * 看图下单筛选
 */
export function screen1(params) {
    return http({
        url: "/api/goodscategory/screen1",
        data: params
    })
}

export function allGoodsList(params) {
    return http({
        url: "/api/goodscategory/screen1",
        data: params
    })
}

// 品牌精选
export function brand(params) {
    return http({
        url: "/api/brand/page",
        data: params
    })
}