<template>
    <div>
        <div class="header-top">
            <div class="top-content">
                <div class="top-left" style="display: none;">
                    <router-link v-if="!logined" to="/login" class="top-div" :class="{ on: index == 0 }">家之物语VIP通道</router-link>
                    <router-link v-if="logined" to="/index" class="top-div" :class="{ on: index == 0 }">家之物语VIP通道</router-link>
                    <router-link to="/introduce" class="top-div" :class="{ on: index == 1 }">家之物语生活馆</router-link>
                </div>
                <div class="top-right">
                    <div class="top-item">
                        <router-link v-if="!logined" to="/login"> <img src="~img/alibaba/member.png" class="ali-icon" />请登录</router-link>
                        <router-link v-if="!logined" to="/register" class="ns-text-color" style="font-size: 12px">注册</router-link>
                        <div v-if="logined" class="member-info">
                            <el-dropdown>
                                <router-link to="/member/info">
                                    {{ member.nickname || member.username }}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </router-link>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="$router.push('/member/info')">会员中心</el-dropdown-item>
                                    <el-dropdown-item @click.native="$router.push('/member/cart')">购物车</el-dropdown-item>
                                    <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <div class="top-item">
                         <a @click="jumpLoginPath('/member/order_list')"><img src="~img/alibaba/order.png" />我的订单</a>
                    </div>
                    <div class="top-item">
                        <a @click="jumpLoginPath('/cms/notice')"><img src="~img/alibaba/news.png" />新闻公告</a>
                    </div>
                    <div class="top-item">
                        <a> <img src="~img/alibaba/tel.png" />服务热线：{{ tel }}</a>
                    </div>
                    <el-dropdown v-if="qrcode">
                        <span class="el-dropdown-link">
                            <img src="~img/alibaba/phone-store.png" />
                            手机店铺
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item> <div class="mobile-qrcode"><img :src="$img(qrcode)" style="width: 71px" /></div></el-dropdown-item>
                        </el-dropdown-menu>

                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getToken } from "@/utils/auth"
import axios from "axios"

export default {
    props: {},
    data() {
        return {
            module: 0,
            tel: "021-20982050"
        }
    },
    created() {
        let that = this;
        this.$store.dispatch("site/qrCodes")
        this.$store.dispatch("member/member_detail")
        this.$store.dispatch("site/defaultFiles")
        this.$store.dispatch("site/addons")
        // this.goToIntroduce()
        console.log("电话");
        this.$store.dispatch("site/siteInfo").then(function(res) {
            that.tel = res.data.service_phone;
        }) // 站点信息
    },
    watch: {},
    methods: {

        logout() {
			// this.page="../../views/auth/login/index.vue";
            this.$router.push('/login');
            this.$store.dispatch("member/logout")
            this.$message("已退出登录")
			// D:/phpstudy_pro/jiazhiwuyu/public/web_os_202106242319/src/layout/components/NsHeaderTop.vue
			// D:/phpstudy_pro/jiazhiwuyu/public/web_os_202106242319/src/views/auth/login/index.vue
        },
        jumpLoginPath( url ) {
            if( this.logined )
            {
                this.$router.push(url);
            } else {
                return this.$message.error("您尚未登录，请先进行登录");
            }
        },
        // goToIntroduce() {
        //     axios
        //         .post("https://jzwy.yfbbc.com/api/member/info", {
        //             app_type: "pc",
        //             app_type_name: "电脑端"
        //         })
        //         .then((res) => {
        //             // if ((res.data.message = "您尚未登录，请先进行登录")) {
        //             //     this.$router.push("/introduce")
        //             // }
        //             console.log(res)
        //         })
        //         .catch((err) => {
        //             this.loadingAd = false
        //         })
        // }
    },
    computed: {
        ...mapGetters(["wapQrcode", "member", "addonIsExit","token"]),
        qrcode: function () {
            return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
        },
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        },
        index() {
            return this.$route.path == "/introduce" ? 1 : 0
        }
    }
}
</script>

<style scoped lang="scss">
.header-top {
    width: 100%;
    height: 40px;
    font-size: 12px;
    background-color: #f5f5f5;

    .el-dropdown {
        font-size: $ns-font-size-sm;
    }
    .top-content {
        width: $width;
        height: 100%;
        margin: 0 auto;
        .top-left {
            height: 100%;
            float: left;
            display: flex;
            align-items: center;
            a {
                height: 25px;
                line-height: 25px;
                margin-right: 10px;
                border-radius: 13px;
                font-size: $ns-font-size-sm;
                padding: 0 12px;
                color: $ns-text-color-black;

                &.on {
                    color: #ffffff;
                    background-color: $base-color;
                }
                &:hover {
                    color: #ffffff;
                    background-color: $base-color;
                }
            }
        }

        .top-right {
            height: 100%;
            float: right;
            font-size: $ns-font-size-sm;
            color: $ns-text-color-gray;
            a {
                float: left;
                line-height: 40px;
                padding: 0 5px;
                display: flex;
                color: $ns-text-color-gray;
                align-items: center;
                img {
                    width: 18px;
                    height: 18px;
                    display: block;
                    margin-right: 5px;
                }
                &:hover {
                    color: $base-color;
                }
                &:first-child {
                    padding-left: 0;
                }
            }
            .el-dropdown-link {
                line-height: 40px;
                padding: 0 10px;
                display: flex;
                align-items: center;
                img {
                    width: 18px;
                    height: 18px;
                    display: block;
                    margin-right: 5px;
                }
            }
            .el-dropdown {
                margin-left: 0;
                &:hover {
                    background-color: transparent;
                }
            }
            div {
                float: left;
                height: 100%;
                margin-left: 10px;
                cursor: pointer;
                line-height: 40px;
                padding: 0 5px;

                &.member-info {
                    margin-left: 0;
                    span:first-child {
                        margin-right: 10px;
                    }
                }
                span:hover {
                    color: $base-color;
                }
                &.el-dropdown:hover {
                    background-color: #fff;
                }
            }

            .top-item {
                padding: 0 10px;
                position: relative;

                &:after {
                    position: absolute;
                    width: 1px;
                    height: 14px;
                    background-color: $ns-text-color-gray;
                    content: "";
                    display: block;
                    right: 0;
                    top: 50%;
                    margin-top: -7px;
                }
            }
        }
    }
}
.mobile-qrcode {
    padding: 10px 0;
}
.router-link-active {
    color: $base-color;
    font-size: 15px;
}
</style>
