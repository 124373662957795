import http from "../../utils/http"

/**
 * 获取帮助列表
 */
export function helpList(params) {
    return http({
        url: "/api/helpclass/lists",
        data: params
    })
}
/**
 * 获取底部导航
 */
export function daoHang(params) {
    return http({
        url: "/api/pc/daohang",
        data: params
    })
}
/**
 * 获取帮助详情
 */
export function helpDetail(params) {
    return http({
        url: "/api/help/info",
        data: params
    })
}
/**
 * 获取帮助详情
 */
export function helpOther(params) {
    return http({
        url: "/api/help/page",
        data: params
    })
}

// 生活馆
export function introduce(params) {
    return http({
        url: "/api/introduce/page",
        data: params
    })
}

// 帮助中心
export function helpcenter(params) {
    return http({
        url: "/api/helpcenter/page",
        data: params
    })
}