<template>
  <aside class="main-sidebar clearfix">
    <div class="main-sidebar-body">
      <ul>
        <li :class="`step-item ${stepIndex == index ? 'act':''}`" v-if="stepData.length && isStep"
            v-for="(item, index) in stepData" :key="index" @click="setStepIndex(index)">
          {{ item.category_name }}
        </li>
        <!--   <li>
               <div class="side-box">
                   <img src="~img/alibaba/wechat-miniapp.png">
       <img src="~img/alibaba/wechat-miniapp-s.png">
                   <p>微信小程序</p>
               </div>
     <div class="side-tips">
       <div class="side-tips-inner">
         <div class="wechat-miniapp-qrcode">
           <img src="~img/test.jpg">
         </div>
       </div>
     </div>
           </li> -->
        <li @click="$router.push('/member/footprint')">
          <div class="side-box">
            <img src="~img/alibaba/footmark.png">
            <img src="~img/alibaba/footmark-s.png">
            <p>我的足迹</p>
          </div>
        </li>
        <li @click="$router.push('/member/collection')">
          <div class="side-box">
            <img src="~img/alibaba/collect.png">
            <img src="~img/alibaba/collect-s.png">
            <p>我的收藏</p>
          </div>
        </li>
        <li @click="showServiceFn()">
          <div class="side-box">
            <img src="~img/alibaba/server.png">
            <img src="~img/alibaba/server-s.png">
            <p>在线客服</p>
          </div>
        </li>
        <li @click="toTop">
          <div class="side-box">
            <img src="~img/alibaba/return-top.png">
            <img src="~img/alibaba/return-top-s.png">
            <p>返回顶部</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="main-sidebar-right">
      <div id="mainSidebarHistoryProduct" class="history-product"></div>
    </div>
    <!--联系客服弹窗-->
<!--    <servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:0,logo:siteInfo.logo,shop_name:'平台客服'}"></servicerMessage>-->
  </aside>
</template>

<script>
import {mapGetters} from "vuex"
import {
  shopServiceOpen
} from "@/api/website.js"
import servicerMessage from "@/components/message/servicerMessage";

export default {
  props: {
    stepData: {
      type: Array
    },
    isStep: {
      type: Boolean
    },
    stepIndex: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      serviceConfig: {},
      serverType:'third',
    }
  },
  computed: {
    ...mapGetters(["cartCount", "wapQrcode", "siteInfo", "member"]),
    qrcode: function () {
      return this.wapQrcode === "" ? "" : this.wapQrcode.path.h5.img
    },
    logined: function () {
      return this.member !== undefined && this.member !== "" && this.member !== {}
    }
  },
  components: {
    servicerMessage
  },
  created() {
    this.shopServiceOpen()
    this.$store.dispatch("site/qrCodes")
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  watch: {},
  methods: {
    handleScroll() {
      // console.log(this.stepData)
      this.visible = window.pageYOffset > 300
    },
    shopServiceOpen() {
      shopServiceOpen().then((res) => {
        // console.log(res,"res");
        if (res.code == 0) {
          this.serviceConfig = res.data;
        }
      })
    },
    toTop() {
      let timer = setInterval(function () {
        let osTop = document.documentElement.scrollTop || document.body.scrollTop
        let ispeed = Math.floor(-osTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed
        this.isTop = true
        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 20)
    },
    // 打开客服弹窗
    showServiceFn() {
      //if (this.logined) {
        if (this.serverType == 'third') {
          window.open(this.siteInfo.servicer_url, "_blank");
          //window.open(this.serverThird, "_blank");
        } else if (this.serverType == 'system') {
          this.hackReset = true;
          this.$refs.servicerMessage.show()
        }
      // } else {
      //   this.$message({
      //     message: "您还未登录",
      //     type: "warning"
      //   })
      // }

    },
    setStepIndex(index) {
      this.$emit('setStepIndex', index)
    }
  }
}
</script>

<style scoped lang="scss">
.main-sidebar {
  width: 90px;
  position: fixed;
  top: 70%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 400;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, .25);
  border-radius: 10px;
  padding: 10px 0;

  ul {
    width: 100%;
    box-sizing: border-box;

    li {
      position: relative;
      margin-bottom: 20px;
      padding: 0 5px;
      user-select: none;

      &.step-item {
        font-size: $ns-font-size-base;
        color: $ns-text-color-gray;
        text-align: center;
        cursor: pointer;
        line-height: 18px;
        margin-bottom: 18px;

        &.act {
          border-right: 2px solid $base-color;
          color: $base-color;
        }
      }

      .side-box {
        cursor: pointer;

        img {
          width: 30px;
          height: 30px;
          display: block;
          margin: 0 auto 5px;

          &:nth-child(2) {
            display: none;
          }
        }

        p {
          font-size: $ns-font-size-base;
          color: $ns-text-color-gray;
          text-align: center;
        }

        &:hover {
          p {
            color: $base-color;
          }

          img {
            &:nth-child(1) {
              display: none;
            }

            &:nth-child(2) {
              display: block;
            }
          }
        }
      }

      .side-tips {
        position: absolute;
        top: 0;
        transform: translateX(calc(-100% - 5px));
        visibility: hidden;
        opacity: 0;
        transition: all .3s;

        .side-tips-inner {
          padding: 5px;
          border-radius: 5px;
          background-color: #ffffff;
          box-shadow: 0 0 5px rgba(0, 0, 0, .25);
          position: relative;
          z-index: 10;
        }

        &:after {
          content: '';
          height: 8px;
          width: 8px;
          background-color: #ffffff;
          display: block;
          position: absolute;
          right: -5px;
          top: 22px;
          z-index: 9;
          box-shadow: 0 0 5px rgba(0, 0, 0, .25);
          transform: rotateZ(135deg) skew(10deg, 10deg);
        }
      }

      &:hover {
        .side-tips {
          transform: translateX(calc(-100% - 15px));
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
</style>
