<template>
    <el-container>
        <!-- 头部 -->
        <!--   <div class="banner" v-loading="loadingAd" v-if="is_show">
            <el-carousel height="70px" arrow="hover" direction="vertical" indicator-position="none">
                <el-carousel-item v-for="item in adList" :key="item.adv_id" :style="{ backgroundColor: item.background }">
                    <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                </el-carousel-item>
            </el-carousel>
            <i class="el-icon-circle-close" @click="closeAd"></i>
        </div> -->
        <el-header><ns-header /></el-header>
        <el-main>
            <transition name="slide"><router-view /></transition>

            <!-- 右侧栏 -->
            <ns-aside :stepIndex="stepIndex" :stepData="stepData" @setStepIndex="setStepIndex" />
          <!--:isStep="isStep"-->
        </el-main>
        <!-- 底部 -->
        <el-footer><ns-footer /></el-footer>
    </el-container>
</template>
<script>
import NsHeader from "./components/NsHeader"
import NsFooter from "./components/NsFooter"
import NsAside from "./components/NsAside"
import { getToken, setToken, removeToken } from "@/utils/auth"
import { adList } from "../api/website"
import axios from "axios"

export default {
    name: "Layout",
    components: {
        NsHeader,
        NsFooter,
        NsAside
    },
    created() {
        this.getAdList()
    },
    data: () => {
        return {
            loadingAd: true,
            adList: [],
            is_show: true,
            indexTopAdNum: 0,
            stepData: [],
            stepIndex: 0,
            isStep: false,
            is_login: "",
            token: getToken()
        }
    },
    mounted() {
        /* setInterval(() => {
				console.log(this)
			},5000) */
    },
    computed: {},
    watch: {},
    methods: {
        // getAdList() {
        //     if (this.$store.state.app.indexTopAdNum >= 3) {
        //         this.loadingAd = false
        //         this.is_show = false
        //         return
        //     }
        //     if (this.is_login == 0) {
        //         console.log(this)
        //     }
        //     adList({ keyword: "NS_PC_INDEX_TOP" })
        //         .then((res) => {
        //             this.adList = res.data.adv_list
        //             if (res.is_login == 0) {
        //                 console.log("未登录")
        //             }
        //             // console.log(res)
        //             for (let i = 0; i < this.adList.length; i++) {
        //                 if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
        //             }
        //             this.loadingAd = false
        //         })
        //         .catch((err) => {
        //             this.loadingAd = false
        //             console.log("报错")
        //         })
        // },
        getAdList() {
            if (this.$store.state.app.indexTopAdNum >= 3) {
                this.loadingAd = false
                this.is_show = false
                return
            }
            axios
                .post("https://admin.sungsavip.com/api/adv/detail", {
                    keyword: "NS_PC_INDEX_TOP"
                })
                .then((res) => {
                    this.adList = res.data.adv_list
                    // console.log(45514, this.is_login)
                    // console.log("token", this.token)
                    if (this.token == undefined) {
                        // console.log("未登录", this)
                        //this.goTo()
                        //return
                        // this.is_login = 1
                    }
                    // console.log(res)
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch((err) => {
                    this.loadingAd = false
                    // console.log("报错")
                })
        },
        //跳转到家之物语生活馆
        goTo() {
            this.$router.push("/index")
        },
        closeAd() {
            this.is_show = false
            this.indexTopAdNum = this.$store.state.app.indexTopAdNum
            this.indexTopAdNum++
            this.$store.commit("app/SET_INDEXTOPADNUM", this.indexTopAdNum)
        },
        setStepIndex(index) {
            let offsetTop = document.documentElement.scrollTop || document.body.scrollTop
            let $ = document.getElementById(`floor-${index}`).getBoundingClientRect()
            let osTop = $.top + offsetTop - 200
            document.documentElement.scrollTop = document.body.scrollTop = osTop
            this.stepIndex = index
        }
    }
}
</script>
<style lang="scss" scoped>
.banner {
    text-align: center;
    height: 70px;
    position: relative;
    i {
        font-size: 30px;
        position: absolute;
        z-index: 100;
        right: 280px;
        top: 10px;
        cursor: pointer;
        color: #d4d4d4;
    }
}
.el-header {
    padding: 0;
    height: 180px !important;
}
.el-footer {
    padding: 0;
    height: auto !important;
    background-color: #fff;
    padding-top: 45px;
}
.el-main {
    // background-color: #fff!important;
}
</style>
