import MemberLayout from "../../layout/member"

// 会员详情相关路由
const memberDetailRoutes = [
{
	path: "batchOrder",
	name: "batchOrder",
	meta: {
		title: '批量下单',
		module: "member",
		auth: true,
	},
	component: () => import("@/views/member/batch_order")
},
{
	path: "batchReserveOrder",
	name: "batchReserveOrder",
	meta: {
		title: '批量下预订单',
		module: "member",
		auth: true,
	},
	component: () => import("@/views/member/batch_reserve_order")
},
{
	path: "index",
	name: "member",
	meta: {
		title: '会员首页',
		module: "member",
		auth: true,
	},
	component: () => import("@/views/member/index")
},
{
	path: "message",
	name: "message",
	meta: {
		module: "member",
		title: '给经理发消息',
		auth: true
	},
	component: () => import("@/views/member/message")
},
{
	path: "return_message",
	name: "return_message",
	meta: {
		module: "member",
		title: '经理回复消息',
		auth: true
	},
	component: () => import("@/views/member/return_message")
},
{
	path: "security",
	name: "security",
	meta: {
		module: "member",
		title: '密码管理',
		auth: true
	},
	component: () => import("@/views/member/security")
},
{
	path: "account",
	name: "account",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/member/account")
},
{
	path: "delivery_address",
	name: "delivery_address",
	meta: {
		module: "member",
		title: '地址管理',
		auth: true
	},
	component: () => import("@/views/member/delivery_address")
},
{
	path: "address_edit",
	name: "address_edit",
	meta: {
		module: "member",
		title: '地址编辑',
		auth: true,
		// parentRouter: "delivery_address" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/member/address_edit")
},
{
	path: "collection",
	name: "collection",
	meta: {
		module: "我的收藏夹",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/member/collection")
},
{
	path: "cart",
	name: "cart",
	meta: {
		module: "member",
		title: '我的购物车',
		auth: true
	},
	component: () => import("@/views/member/cart")
},
{
	path: "my_coupon",
	name: "my_coupon",
	meta: {
		module: "member",
		title: '我的优惠券',
		auth: true
	},
	component: () => import("@/views/member/coupon")
},
{
	path: "footprint",
	name: "footprint",
	meta: {
		module: "member",
		title: '我的足迹',
		auth: true
	},
	component: () => import("@/views/member/footprint")
},
{
	path: "info",
	name: "info",
	meta: {
		module: "member",
		title: '会员信息',
		auth: true
	},
	component: () => import("@/views/member/info")
},
{
	path: "level",
	name: "level",
	meta: {
		module: "member",
		title: '等级',
		auth: true
	},
	component: () => import("@/views/member/level")
},
{
	path: "signin",
	name: "signin",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/member/signin")
},
{
	path: "order_list",
	name: "order_list",
	meta: {
		module: "order",
		title: '订单列表',
		auth: true
	},
	component: () => import("@/views/order/list")
},
{
	path: "book_list",
	name: "book_list",
	meta: {
		module: "order",
		title: '预订单列表',
		auth: true
	},
	component: () => import("@/views/order/book_list")
},
{
	path: "my_point",
	name: "my_point",
	meta: {
		module: "member",
		title: '我的积分',
		auth: true
	},
	component: () => import("@/views/member/my_point")
},
{
	path: "activist",
	name: "activist",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/order/activist")
},
{
	path: "refund_detail",
	name: "refund_detail",
	meta: {
		module: "order",
		title: '售后详情',
		auth: true,
		// parentRouter: "activist" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/refund_detail")
},
{
	path: "refund",
	name: "refund",
	meta: {
		module: "order_list",
		title: '售后',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/refund")
},
{
	path: "complain",
	name: "complain",
	meta: {
		module: "order",
		title: '申诉',
		auth: true,
		parentRouter: "activist" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/complain")
},
{
	path: "order_detail",
	name: "order_detail",
	meta: {
		module: "order",
		title: '预订单详情',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/detail")
},
{
	path: "order_detail2",
	name: "order_detail2",
	meta: {
		module: "order",
		title: '订单详情',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/detail2")
},
{
	path: "order_detail_local_delivery",
	name: "order_detail_local_delivery",
	meta: {
		module: "order",
		title: '订单配送信息',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/detail_local_delivery")
},
{
	path: "order_detail_pickup",
	name: "order_detail_pickup",
	meta: {
		module: "order",
		title: '订单配送',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/detail_pickup")
},
{
	path: "order_detail_virtual",
	name: "order_detail_virtual",
	meta: {
		module: "order",
		title: '虚拟订单',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/detail_virtual")
},
{
	path: "logistics",
	name: "logistics",
	meta: {
		module: "order",
		title: '物流',
		auth: true,
		// parentRouter: "order_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/logistics")
},
{
	path: "verification",
	name: "verification",
	meta: {
		module: "order",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/order/verification")
},
{
	path: "verification_list",
	name: "verification_list",
	meta: {
		module: "order",
		title: '会员首页',
		auth: true
	},
	component: () => import("@/views/order/verification_list")
},
{
	path: "verification_detail",
	name: "verification_detail",
	meta: {
		module: "order",
		title: '会员首页',
		auth: true,
		parentRouter: "verification_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/order/verification_detail")
},
{
	path: "account_list",
	name: "account_list",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true
	},
	component: () => import("../../views/member/account_list")
},
{
	path: "account_edit",
	name: "account_edit",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true,
		parentRouter: "account_list" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("../../views/member/account_edit")
},
{
	path: "apply_withdrawal",
	name: "apply_withdrawal",
	meta: {
		module: "member",
		title: '会员首页',
		auth: true,
		parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("../../views/member/apply_withdrawal")
},
{
	path: "withdrawal",
	name: "withdrawal",
	meta: {
		module: "member",
		title: '账户明细',
		auth: true
	},
	component: () => import("@/views/member/withdrawal")
},
{
	path: "withdrawal_detail",
	name: "withdrawal_detail",
	meta: {
		module: "member",
		title: '账户明细详情',
		auth: true,
		parentRouter: "withdrawal" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/member/withdrawal_detail")
},
{
	path: "recharge_list",
	name: "recharge_list",
	meta: {
		module: "member",
		title: '充值列表',
		auth: true,
		parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/member/recharge_list")
},
{
	path: "recharge_detail",
	name: "recharge_detail",
	meta: {
		module: "member",
		title: '充值详情',
		auth: true,
		parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/member/recharge_detail")
},
{
	path: "recharge_order",
	name: "recharge_order",
	meta: {
		module: "member",
		title: '充值订单',
		auth: true,
		parentRouter: "account" // 记录上级路由，用于会员中心选择左侧菜单
	},
	component: () => import("@/views/member/recharge_order")
},
{
	path: "payment",
	name: "payment",
	meta: {
		module: "member",
		title: '支付方式',
		auth: true
	},
	component: () => import("@/views/member/payment")
},
]

// 会员订单相关路由
const memberOrderRoutes = [{
	path: "/evaluate",
	name: "evaluate",
	meta: {
		module: "order",
		title: '评价',
		auth: true
	},
	component: () => import("@/views/order/evaluate")
},
{
	path: "/payment",
	name: "payment",
	meta: {
		module: "order",
		title: '支付方式	',
		auth: true
	},
	component: () => import("@/views/order/payment")
},
{
	path: "/pay",
	name: "pay",
	meta: {
		module: "pay",
		title: '支付',
		auth: true
	},
	component: () => import("@/views/pay/index")
},
{
	path: "/result",
	name: "result",
	meta: {
		module: "pay",
		title: '支付结果',
		auth: true
	},
	component: () => import("@/views/pay/result")
}
]

// 会员模块
export default {
	path: "/member",
	component: MemberLayout,
	redirect: "/",
	alwaysShow: true,
	meta: {
		title: "首页",
		titlePath: "/",
		mainCss: {
			backgound: "#f4f4f4"
		},
	},
	name: "MemberIndex",
	children: [{
		path: "/member",
		name: "home",
		redirect: "info",
		meta: {
			title: "会员中心",
			mainCss: {
				backgound: "#f4f4f4"
			},
		},
		children: memberDetailRoutes,
		component: () => import("@/views/member/home")
	},

	...memberOrderRoutes
	]
}
